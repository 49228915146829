import React, { useContext, useState } from "react";
import useAdminPage from "../../../../contexts/admin/AdminMenuConsumer";
import { useTranslation } from "react-i18next";
import { ApiFetchData } from "../../../../data/api";
import { GetPayments } from "../../../../data/api-response";
import { useAsync, useUpdate } from "react-use";
import { httpGet } from "../../../../utils/get";
import Loading from "../../general/Loading/Loading";
import ApiContext from "../../../../contexts/api/ApiContext";
import styles from "./Orders.module.scss";
import { formatter } from "../../../../utils/currency";
import { Payment } from "../../../../data/interfaces";
import Icon from "../../../ui/Icon/Icon";
import useSnackBar from "../../../../contexts/snackbar/SnackBarConsumer";
import { httpPost } from "../../../../utils/post";
import AdminContext from "../../../../contexts/admin/AdminContext";

type ProcessStatus = "sent" | "in-process" | "pending";

const Orders: React.FC = () => {
    useAdminPage("orders");
    const { t } = useTranslation("adminOrders");
    const [res, setRes] = useState<ApiFetchData<GetPayments>>();
    const { apiServer } = useContext(ApiContext);
    const [editMode, setEditMode] = useState<{ id: number; status: ProcessStatus }[]>();
    const addSnackBar = useSnackBar();
    const { token } = useContext(AdminContext);
    const update = useUpdate();

    useAsync(async () => {
        setRes(await httpGet(`${apiServer}/payment`, token));
    });

    if (!res) {
        return <Loading />;
    }

    if (!res.success) {
        return <div>{t("adminGeneral:error")}</div>;
    }

    const isEditMode = (id: number) => {
        if (!editMode) return false;
        for (const e of editMode) {
            if (e.id === id) return true;
        }
        return false;
    };

    const setEditModeTrue = (id: number, status: ProcessStatus) => {
        if (!editMode) {
            setEditMode([{ id, status }]);
            return;
        }
        for (const e of editMode) {
            if (e.id === id) return;
        }
        setEditMode([...editMode, { id, status }]);
    };

    const setEditModeFalse = (id: number) => {
        setEditMode(editMode?.filter(el => el.id !== id));
        update();
    };

    const getProcessStatus = (r: Payment): ProcessStatus => {
        if (editMode) {
            for (const e of editMode) {
                if (e.id === r.id) return e.status;
            }
        }
        return r.process_status;
    };

    return (
        <div className={styles.wrapper}>
            {res.data.data.length === 0 ? (
                <i>{t("no-orders")}</i>
            ) : (
                res.data.data.length !== 0 && (
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th>{t("name")}</th>
                                <th>{t("email")}</th>
                                <th>{t("address")}</th>
                                <th>{t("date")}</th>
                                <th>{t("payment-status")}</th>
                                <th>{t("process-status")}</th>
                                <th>{t("order-content")}</th>
                                <th>{t("total-price")}</th>
                                <th />
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {res.data.data
                                .sort((a, b) => {
                                    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                                })
                                .map(r => (
                                    <tr key={r.id}>
                                        <td>{r.name}</td>
                                        <td>{r.email}</td>
                                        <td>
                                            {r.street} {r.house_number}
                                            <br />
                                            {r.postal} {r.place}
                                        </td>
                                        <td>{r.created_at}</td>
                                        <td>{t(`payment-${r.mollie_payment_status}`)}</td>
                                        <td>
                                            <select
                                                onChange={e => setEditModeTrue(r.id, e.target.value as ProcessStatus)}
                                                value={getProcessStatus(r)}
                                            >
                                                {["pending", "in-process", "sent"].map(val => (
                                                    <option key={val} value={val}>
                                                        {t(`process-${val}`)}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                        <td>
                                            <div className={styles.order}>
                                                {r.cds.map((c, i) => (
                                                    <div key={i} className={styles.cd}>
                                                        <div className={styles.detail}>
                                                            <div className={styles.title}>{t("title")}</div>
                                                            <div>{c.title}</div>
                                                        </div>
                                                        <div className={styles.detail}>
                                                            <div className={styles.title}>{t("price")}</div>
                                                            <div>{formatter.format(c.price)}</div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </td>
                                        <td>
                                            {formatter.format(
                                                Math.max.apply(
                                                    0,
                                                    r.cds.map(c => c.shipping_costs)
                                                ) + r.cds.map(c => c.price).reduce((a, b) => a + b)
                                            )}
                                        </td>
                                        <td>
                                            <Icon.DarkBlue
                                                name="trash"
                                                onClick={async () => {
                                                    if (window.confirm(t("adminGeneral:delete-confirmation"))) {
                                                        const response = await httpPost(
                                                            `${apiServer}/payment/delete/${r.id}`,
                                                            {},
                                                            token
                                                        );
                                                        if (!response.success) {
                                                            addSnackBar(t("adminGeneral:fail-delete-no-title"), "error");
                                                        } else {
                                                            addSnackBar(
                                                                t("adminGeneral:success-delete-no-title"),
                                                                "success"
                                                            );
                                                            res.data.data = res.data.data.filter(res => res.id !== r.id);
                                                            setEditMode(editMode?.filter(e => e.id !== r.id));
                                                            update();
                                                        }
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td className={styles.buttons}>
                                            {isEditMode(r.id) && (
                                                <>
                                                    <b
                                                        onClick={() => {
                                                            if (window.confirm(t("update-confirm"))) {
                                                                const newStatus = getProcessStatus(r);
                                                                httpPost(
                                                                    `${apiServer}/payment/${r.id}`,
                                                                    {
                                                                        process_status: newStatus,
                                                                    },
                                                                    token
                                                                ).then(result => {
                                                                    if (result.success) {
                                                                        addSnackBar(t("adminGeneral:updated"), "success");
                                                                        let copy = res;
                                                                        copy.data.data = res.data.data.map(oldRes => {
                                                                            if (oldRes.id === r.id) {
                                                                                oldRes.process_status = newStatus;
                                                                            }
                                                                            return oldRes;
                                                                        });
                                                                        setRes(copy);
                                                                        setEditModeFalse(r.id);
                                                                    } else {
                                                                        addSnackBar(t("adminGeneral:not-updated"), "error");
                                                                    }
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        {t("adminGeneral:save-changes")}
                                                    </b>
                                                    <b onClick={() => setEditModeFalse(r.id)}>{t("adminGeneral:cancel")}</b>
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                )
            )}
        </div>
    );
};

export default Orders;
