import React, { useContext, useState } from "react";
import useAdminPage from "../../../../contexts/admin/AdminMenuConsumer";
import Page from "../../ui/Page/Page";
import { useTranslation } from "react-i18next";
import { ApiFetchData } from "../../../../data/api";
import { GetPhotos, GetVideos } from "../../../../data/api-response";
import Loading from "../../general/Loading/Loading";
import ApiContext from "../../../../contexts/api/ApiContext";
import { useAsync, useUpdate } from "react-use";
import { httpGet } from "../../../../utils/get";
import styles from "./Media.module.scss";
import Icon from "../../../ui/Icon/Icon";
import useSnackBar from "../../../../contexts/snackbar/SnackBarConsumer";
import FileInput from "../../../ui/FileInput/FileInput";
import TextInput from "../../../ui/TextInput/TextInput";
import Button from "../../../ui/Button/Button";
import { httpPost } from "../../../../utils/post";
import AdminContext from "../../../../contexts/admin/AdminContext";

const Media: React.FC = () => {
    useAdminPage("media");
    const [photos, setPhotos] = useState<ApiFetchData<GetPhotos>>();
    const [videos, setVideos] = useState<ApiFetchData<GetVideos>>();
    const [photoLoading, setPhotoLoading] = useState(false);
    const [videoLoading, setVideoLoading] = useState(false);
    const [photo, setPhoto] = useState("");
    const [video, setVideo] = useState("");
    const { t } = useTranslation("adminMedia");
    const { apiServer } = useContext(ApiContext);
    const addSnackBar = useSnackBar();
    const { token } = useContext(AdminContext);
    const update = useUpdate();

    useAsync(async () => {
        setPhotos(await httpGet(`${apiServer}/media/photo`));
        setVideos(await httpGet(`${apiServer}/media/video`));
    });

    if (!photos || !videos) {
        return <Loading />;
    }

    if (!photos.success || !videos.success) {
        return <div>{t("adminGeneral:error")}</div>;
    }

    const addPhoto = (e: React.FormEvent) => {
        e.preventDefault();
        if (photo === "") {
            addSnackBar(t("adminGeneral:fill-fields"), "error");
            return;
        } else {
            setPhotoLoading(true);
            httpPost<{ data: { id: number } }>(`${apiServer}/media/photo`, { image: photo }, token).then(result => {
                setPhotoLoading(false);
                if (result.success) {
                    addSnackBar(t("adminGeneral:added"), "success");
                    photos.data.data.push({
                        id: result.data.data.id,
                        image: photo,
                    });
                    update();
                } else {
                    addSnackBar(t("adminGeneral:not-added"), "error");
                }
            });
        }
    };

    const addVideo = (e: React.FormEvent) => {
        e.preventDefault();
        if (video === "") {
            addSnackBar(t("adminGeneral:fill-fields"), "error");
            return;
        } else {
            setVideoLoading(true);
            httpPost<{ data: { id: number } }>(`${apiServer}/media/video`, { url: video }, token).then(result => {
                setVideoLoading(false);
                if (result.success) {
                    addSnackBar(t("adminGeneral:added"), "success");
                    videos.data.data.push({
                        id: result.data.data.id,
                        url: video,
                    });
                    update();
                } else {
                    addSnackBar(t("adminGeneral:not-added"), "error");
                }
            });
        }
    };

    return (
        <Page>
            <div>
                <h3>{t("photos")}</h3>
                <div className={styles.photos}>
                    {photos.data.data.length === 0 ? (
                        <i>{t("no-photos")}</i>
                    ) : (
                        photos.data.data.map(p => (
                            <div className={styles.photo} key={p.id}>
                                <img src={p.image} alt={p.image} />
                                <div className={styles.hover}>
                                    <Icon.OffWhite
                                        name="trash"
                                        onClick={async () => {
                                            if (window.confirm(t("adminGeneral:delete-confirmation"))) {
                                                const response = await httpPost(
                                                    `${apiServer}/media/photo/delete/${p.id}`,
                                                    {},
                                                    token
                                                );
                                                if (!response.success) {
                                                    addSnackBar(t("adminGeneral:fail-delete-no-title"), "error");
                                                } else {
                                                    addSnackBar(t("adminGeneral:success-delete-no-title"), "success");
                                                    photos.data.data = photos.data.data.filter(photo => photo.id !== p.id);
                                                    update();
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        ))
                    )}
                </div>
                <h3>{t("videos")}</h3>
                <div className={styles.videos}>
                    {videos.data.data.length === 0 ? (
                        <i>{t("no-videos")}</i>
                    ) : (
                        videos.data.data.map(v => (
                            <div className={styles.video} key={v.id}>
                                <iframe
                                    className={styles.video}
                                    key={v.id}
                                    title={v.url}
                                    src={v.url}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                                <div className={styles.hover}>
                                    <Icon.OffWhite
                                        name="trash"
                                        onClick={async () => {
                                            if (window.confirm(t("adminGeneral:delete-confirmation"))) {
                                                const response = await httpPost(
                                                    `${apiServer}/media/video/delete/${v.id}`,
                                                    {},
                                                    token
                                                );
                                                if (!response.success) {
                                                    addSnackBar(t("adminGeneral:fail-delete-no-title"), "error");
                                                } else {
                                                    addSnackBar(t("adminGeneral:success-delete-no-title"), "success");
                                                    videos.data.data = videos.data.data.filter(video => video.id !== v.id);
                                                    update();
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
            <div>
                <h3>{t("photos")}</h3>
                <form onSubmit={addPhoto}>
                    <FileInput onChange={setPhoto} />
                    <Button loading={photoLoading}>{t("adminGeneral:add")}</Button>
                </form>
                <h3>{t("videos")}</h3>
                <form onSubmit={addVideo}>
                    <TextInput label={t("video-url")} onChange={setVideo} />
                    <Button loading={videoLoading}>{t("adminGeneral:add")}</Button>
                </form>
            </div>
        </Page>
    );
};

export default Media;
