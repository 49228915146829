import React, { useMemo } from "react";
import { ApiFetch } from "../../../data/api";
import { GetPage } from "../../../data/api-response";
import { useTranslation } from "react-i18next";

interface Props {
    data: ApiFetch<GetPage>;
}

const ApiPage: React.FC<Props> = props => {
    const { i18n } = useTranslation();
    const urlRegex = /(https?:\/\/\S+)/g;

    const text = useMemo(() => {
        if (props.data.isFetching || !props.data.data.success) {
            return "";
        }
        const { content_en, content_nl, content_de } = props.data.data.data.data;
        switch (i18n.language) {
            case "en":
                return content_en;
            case "de":
                return content_de;
            case "nl":
            default:
                return content_nl;
        }
    }, [i18n.language, props.data]);

    return <p dangerouslySetInnerHTML={{ __html: text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>') }} />;
};

export default ApiPage;
