import React, { useContext, useState } from "react";
import useAdminPage from "../../../../contexts/admin/AdminMenuConsumer";
import Page from "../../ui/Page/Page";
import { useTranslation } from "react-i18next";
import { ApiFetchData } from "../../../../data/api";
import { GetEvents } from "../../../../data/api-response";
import { useAsync } from "react-use";
import { httpGet } from "../../../../utils/get";
import Loading from "../../general/Loading/Loading";
import ApiContext from "../../../../contexts/api/ApiContext";
import TextInput from "../../../ui/TextInput/TextInput";
import TextArea from "../../../ui/TextArea/TextArea";
import FileInput from "../../../ui/FileInput/FileInput";
import Button from "../../../ui/Button/Button";
import { httpPost } from "../../../../utils/post";
import useSnackBar from "../../../../contexts/snackbar/SnackBarConsumer";
import Icon from "../../../ui/Icon/Icon";
import AdminContext from "../../../../contexts/admin/AdminContext";

const Events: React.FC = () => {
    useAdminPage("events");
    const [res, setRes] = useState<ApiFetchData<GetEvents>>();
    const { apiServer } = useContext(ApiContext);
    const { t } = useTranslation("adminEvents");
    const [image, setImage] = useState("");
    const [date, setDate] = useState("");
    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [location, setLocation] = useState("");
    const [editId, setEditId] = useState(0);
    const [loading, setLoading] = useState(false);
    const addSnackBar = useSnackBar();
    const { token } = useContext(AdminContext);

    async function refetch() {
        setRes(await httpGet<GetEvents>(`${apiServer}/event?with_images=false`));
    }

    useAsync(refetch);

    if (!res) {
        return <Loading />;
    }

    if (!res.success) {
        return <div>{t("adminGeneral:error")}</div>;
    }

    const dateToApi = (date: string) => date.replace("T", " ") + ":00";

    const apiToDate = (date: string) => date.replace(" ", "T").substring(0, date.lastIndexOf(":00"));

    const resetEditMode = () => {
        setEditId(0);
        setTitle("");
        setDesc("");
        setImage("");
        setDate("");
        setLocation("");
    };

    const addEvent = (e: React.FormEvent) => {
        e.preventDefault();
        if (editId !== 0) {
            updateEvent();
            return;
        }
        if (title === "" || desc === "" || date === "" || location === "" || image === "") {
            addSnackBar(t("adminGeneral:fill-fields"), "error");
        } else {
            setLoading(true);
            httpPost<{ data: { id: number } }>(
                `${apiServer}/event`,
                {
                    title: title,
                    description: desc,
                    date: dateToApi(date),
                    location: location,
                    image: image,
                },
                token
            ).then(result => {
                setLoading(false);
                if (result.success) {
                    addSnackBar(t("adminGeneral:added"), "success");
                    refetch();
                } else {
                    addSnackBar(t("adminGeneral:not-added"), "error");
                }
            });
        }
    };

    const updateEvent = () => {
        if (editId === 0) {
            addSnackBar(t("cd-not-updated"), "error");
            return;
        }
        let updated: { [key: string]: string } = {};
        if (title !== "") updated["title"] = title;
        if (desc !== "") updated["description"] = desc;
        if (location !== "") updated["location"] = location;
        if (date !== "") updated["date"] = dateToApi(date);
        if (image !== "") updated["image"] = image;
        setLoading(true);
        httpPost(`${apiServer}/event/${editId}`, updated, token).then(result => {
            setLoading(false);
            if (result.success) {
                addSnackBar(t("adminGeneral:updated"), "success");
                res.data.data.forEach(r => {
                    if (r.id === editId) {
                        if (title) r.title = title;
                        if (desc) r.description = desc;
                        if (location !== "") r.location = location;
                        if (date !== "") r.date = date;
                        if (image) r.image = image;
                    }
                    return r;
                });
                resetEditMode();
                refetch();
            } else {
                addSnackBar(t("adminGeneral:not-updated"), "error");
            }
        });
    };

    return (
        <Page>
            {res.data.data.length === 0 ? (
                <i>{t("no-events")}</i>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>{t("date")}</th>
                            <th>{t("title")}</th>
                            <th>{t("location")}</th>
                            <th />
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {res.data.data
                            // Sort ascending
                            .sort((a, b) => new Date(b.datetime_raw).getTime() - new Date(a.datetime_raw).getTime())
                            .map(e => (
                                <tr key={e.id}>
                                    <td>{e.datetime}</td>
                                    <td>{e.title}</td>
                                    <td>{e.location}</td>
                                    <td>
                                        <Icon.DarkBlue
                                            name="trash"
                                            onClick={async () => {
                                                if (window.confirm(t("adminGeneral:delete-confirmation"))) {
                                                    const response = await httpPost(
                                                        `${apiServer}/event/delete/${e.id}`,
                                                        {},
                                                        token
                                                    );
                                                    if (!response.success) {
                                                        addSnackBar(
                                                            t("adminGeneral:fail-delete", { title: e.title }),
                                                            "error"
                                                        );
                                                    } else {
                                                        addSnackBar(
                                                            t("adminGeneral:success-delete", { title: e.title }),
                                                            "success"
                                                        );
                                                        res.data.data = res.data.data.filter(ev => ev.id !== e.id);
                                                        await refetch();
                                                    }
                                                }
                                            }}
                                        />
                                    </td>
                                    <td>
                                        {editId !== e.id && (
                                            <Icon.DarkBlue
                                                name="edit"
                                                onClick={() => {
                                                    setEditId(e.id);
                                                    setTitle(e.title);
                                                    setDesc(e.description);
                                                    setLocation(e.location);
                                                    setDate(apiToDate(e.date));
                                                    setImage(e.image);
                                                }}
                                            />
                                        )}
                                        {editId === e.id && <Icon.DarkBlue name="close" onClick={resetEditMode} />}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            )}
            <form onSubmit={addEvent}>
                <TextInput label={t("title")} onChange={setTitle} defaultValue={title} />
                <TextArea label={t("description")} onChange={setDesc} defaultValue={desc} rows={7} resizeY />
                <TextInput label={t("location")} onChange={setLocation} defaultValue={location} />
                <FileInput onChange={setImage} defaultValue={image} />
                <input type="datetime-local" onChange={e => setDate(e.target.value)} defaultValue={date} />
                <Button loading={loading}>{editId !== 0 ? t("adminGeneral:save-changes") : t("adminGeneral:add")}</Button>
            </form>
        </Page>
    );
};

export default Events;
