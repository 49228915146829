import React from "react";
import { ApiContextType } from "./ApiContext";

// Actions which the reducer accepts
export enum ApiReducerActions {
    SET_EVENTS,
    SET_CDS,
    SET_VIDEOS,
    SET_PHOTOS,
    SET_HOME_PAGE,
    SET_BIO_PAGE,
    SET_DUO_PAGE,
}

interface ApiReducerType {
    // Type of action
    type: ApiReducerActions;
    // Payload for the action
    payload: any;
}

const ApiReducer: React.Reducer<ApiContextType, ApiReducerType> = (state, reducer): ApiContextType => {
    switch (reducer.type) {
        case ApiReducerActions.SET_EVENTS:
            if (reducer.payload.isFetching || !reducer.payload.data.success) {
                return state;
            }
            return {
                ...state,
                events: {
                    isFetching: false,
                    data: {
                        success: true,
                        data: {
                            data: (!state.events.isFetching && state.events.data.success
                                ? state.events.data.data.data
                                : []
                            ).concat(reducer.payload.data.data.data),
                        },
                    },
                },
            };
        case ApiReducerActions.SET_CDS:
            return {
                ...state,
                cds: reducer.payload,
            };
        case ApiReducerActions.SET_VIDEOS:
            return {
                ...state,
                videos: reducer.payload,
            };
        case ApiReducerActions.SET_PHOTOS:
            return {
                ...state,
                photos: reducer.payload,
            };
        case ApiReducerActions.SET_HOME_PAGE:
            return {
                ...state,
                pageHome: reducer.payload,
            };
        case ApiReducerActions.SET_BIO_PAGE:
            return {
                ...state,
                pageBio: reducer.payload,
            };
        case ApiReducerActions.SET_DUO_PAGE:
            return {
                ...state,
                pageDuo: reducer.payload,
            };
    }
};

export default ApiReducer;
