import React from "react";
import { useTranslation } from "react-i18next";
import usePage from "../../../../contexts/menu/MenuConsumer";
import Page from "../../../ui/Page/Page";
import styles from "./Contact.module.scss";

const Contact: React.FC = () => {
    usePage("contact");
    const { t } = useTranslation("contact");

    return (
        <Page className={styles.page}>
            <form className={styles.form}>
                <h1>{t("contact-me")}</h1>
                <p>
                    {t("contact-desc")}
                    <a href="mailto:info@iddovandergiessen.nl">info@iddovandergiessen.nl</a>
                </p>
                <hr />
                <table className={styles.credits}>
                    <tbody>
                        <tr>
                            <th>{t("credit-design")}</th>
                            <td>Arno van Liere</td>
                        </tr>
                        <tr>
                            <th>{t("credit-photos")}</th>
                            <td>Jaap &amp; Marja Koole</td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </Page>
    );
};

export default Contact;
