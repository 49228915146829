import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import ApiContext, { initialContext } from "./ApiContext";
import ApiReducer, { ApiReducerActions } from "./ApiReducer";
import { httpGet } from "../../utils/get";
import { httpPost } from "../../utils/post";
import async from "async";

interface Props {
    loadImages?: boolean; // Default true
}

const ApiProvider: React.FC<Props> = props => {
    const [state, dispatch] = useReducer(ApiReducer, initialContext);

    const initContext = useCallback(
        async (action: ApiReducerActions, route: string) => {
            dispatch({ type: action, payload: { isFetching: true } });
            const res = await httpGet<any>(`${state.apiServer}${route}`);
            dispatch({ type: action, payload: { isFetching: false, data: res } });
        },
        [state.apiServer]
    );

    // Initialize context
    useEffect(() => {
        async.parallel({
            eventsFuture: () =>
                initContext(ApiReducerActions.SET_EVENTS, `/event?type=future&with_images=${props.loadImages ?? true}`),
            // Don't show fetching state for historical events
            eventsHistory: () =>
                initContext(ApiReducerActions.SET_EVENTS, `/event?type=history&with_images=${props.loadImages ?? true}`),
            cds: () => initContext(ApiReducerActions.SET_CDS, "/cd"),
            videos: () => initContext(ApiReducerActions.SET_VIDEOS, "/media/video"),
            // photos: () => initContext<GetPhotos>(ApiReducerActions.SET_PHOTOS, "/media/photo"),
            home: () => initContext(ApiReducerActions.SET_HOME_PAGE, "/page/home"),
            bio: () => initContext(ApiReducerActions.SET_BIO_PAGE, "/page/biography"),
            duo: () => initContext(ApiReducerActions.SET_DUO_PAGE, "/page/duo"),
        });
    }, [initContext, props.loadImages]);

    /**
     * Send a message.
     * @param name    The name to send in the body.
     * @param email   The email to send in the body.
     * @param message The message to send in the body.
     */
    const sendMessage = useCallback(
        async (name: string, email: string, message: string): Promise<boolean> => {
            const res = await httpPost(`${state.apiServer}/message`, { name, email, message });
            return res.success;
        },
        [state.apiServer]
    );

    const contextValue = useMemo(() => {
        return {
            ...state,
            sendMessage: sendMessage,
        };
    }, [state, sendMessage]);

    return <ApiContext.Provider value={contextValue}>{props.children}</ApiContext.Provider>;
};

export default ApiProvider;
