import React, { useContext, useState } from "react";
import useAdminPage from "../../../../contexts/admin/AdminMenuConsumer";
import { ApiFetchData } from "../../../../data/api";
import { GetCDs } from "../../../../data/api-response";
import { useAsync, useUpdate } from "react-use";
import { httpGet } from "../../../../utils/get";
import { formatter } from "../../../../utils/currency";
import Icon from "../../../ui/Icon/Icon";
import useSnackBar from "../../../../contexts/snackbar/SnackBarConsumer";
import ApiContext from "../../../../contexts/api/ApiContext";
import { useTranslation } from "react-i18next";
import Loading from "../../general/Loading/Loading";
import { httpPost } from "../../../../utils/post";
import Page from "../../ui/Page/Page";
import TextInput from "../../../ui/TextInput/TextInput";
import TextArea from "../../../ui/TextArea/TextArea";
import FileInput from "../../../ui/FileInput/FileInput";
import Button from "../../../ui/Button/Button";
import AdminContext from "../../../../contexts/admin/AdminContext";

const CDs: React.FC = () => {
    useAdminPage("cds");
    const [res, setRes] = useState<ApiFetchData<GetCDs>>();
    const { apiServer } = useContext(ApiContext);
    const { token } = useContext(AdminContext);
    const addSnackBar = useSnackBar();
    const { t } = useTranslation("adminCd");
    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [price, setPrice] = useState("");
    const [shipping, setShipping] = useState("");
    const [image, setImage] = useState("");
    const [editId, setEditId] = useState(0);
    const [loading, setLoading] = useState(false);
    const update = useUpdate();

    useAsync(async () => {
        setRes(await httpGet(`${apiServer}/cd`));
    });

    if (!res) {
        return <Loading />;
    }

    if (!res.success) {
        return <div>{t("adminGeneral:error")}</div>;
    }

    const resetEditMode = () => {
        setEditId(0);
        setTitle("");
        setDesc("");
        setPrice("");
        setImage("");
        setShipping("");
    };

    const addCD = (e: React.FormEvent) => {
        e.preventDefault();
        if (editId !== 0) {
            updateCD();
            return;
        }
        if (title === "" || desc === "" || price === "" || shipping === "" || image === "") {
            addSnackBar(t("adminGeneral:fill-fields"), "error");
        } else {
            setLoading(true);
            httpPost<{ data: { id: number } }>(
                `${apiServer}/cd`,
                {
                    title: title,
                    description: desc,
                    price: Number(price),
                    shipping_costs: Number(shipping),
                    image: image,
                },
                token
            ).then(result => {
                setLoading(false);
                if (result.success) {
                    addSnackBar(t("adminGeneral:added"), "success");
                    res.data.data.push({
                        id: result.data.data.id,
                        title: title,
                        description: desc,
                        price: Number(price),
                        shipping_costs: Number(shipping),
                        image: image,
                    });
                    update();
                } else {
                    addSnackBar(t("adminGeneral:not-added"), "error");
                }
            });
        }
    };

    const updateCD = () => {
        if (editId === 0) {
            addSnackBar(t("cd-not-updated"), "error");
            return;
        }
        let updated: { [key: string]: string } = {};
        if (title !== "") updated["title"] = title;
        if (desc !== "") updated["description"] = desc;
        if (price !== "") updated["price"] = price;
        if (shipping !== "") updated["shipping_costs"] = shipping;
        if (image !== "") updated["image"] = image;
        setLoading(true);
        httpPost(`${apiServer}/cd/${editId}`, updated, token).then(result => {
            setLoading(false);
            if (result.success) {
                addSnackBar(t("adminGeneral:updated"), "success");
                res.data.data.forEach(r => {
                    if (r.id === editId) {
                        if (title) r.title = title;
                        if (desc) r.description = desc;
                        if (price) r.price = Number(price);
                        if (shipping) r.shipping_costs = Number(shipping);
                        if (image) r.image = image;
                    }
                    return r;
                });
                resetEditMode();
                update();
            } else {
                addSnackBar(t("adminGeneral:not-updated"), "error");
            }
        });
    };

    return (
        <Page>
            {res.data.data.length === 0 ? (
                <i>{t("no-cds")}</i>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>{t("title")}</th>
                            <th>{t("description")}</th>
                            <th>{t("price")}</th>
                            <th>{t("shipping")}</th>
                            <th />
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {res.data.data.map(cd => (
                            <tr key={cd.id}>
                                <td>{cd.title}</td>
                                <td>{cd.description}</td>
                                <td>{formatter.format(cd.price)}</td>
                                <td>{formatter.format(cd.shipping_costs)}</td>
                                <td>
                                    <Icon.DarkBlue
                                        name="trash"
                                        onClick={async () => {
                                            if (window.confirm(t("adminGeneral:delete-confirmation"))) {
                                                const response = await httpPost(
                                                    `${apiServer}/cd/delete/${cd.id}`,
                                                    {},
                                                    token
                                                );
                                                if (!response.success && response.error_code === "OPEN_ORDERS") {
                                                    addSnackBar(t("open-orders", { title: cd.title }), "error");
                                                } else if (!response.success) {
                                                    addSnackBar(t("adminGeneral:fail-delete", { title: cd.title }), "error");
                                                } else {
                                                    addSnackBar(
                                                        t("adminGeneral:success-delete", { title: cd.title }),
                                                        "success"
                                                    );
                                                    res.data.data = res.data.data.filter(c => c.id !== cd.id);
                                                    update();
                                                }
                                            }
                                        }}
                                    />
                                </td>
                                <td>
                                    {editId !== cd.id && (
                                        <Icon.DarkBlue
                                            name="edit"
                                            onClick={() => {
                                                setEditId(cd.id);
                                                setTitle(cd.title);
                                                setDesc(cd.description);
                                                setPrice(String(cd.price));
                                                setShipping(String(cd.shipping_costs));
                                            }}
                                        />
                                    )}
                                    {editId === cd.id && <Icon.DarkBlue name="close" onClick={resetEditMode} />}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <form onSubmit={addCD}>
                <TextInput label={t("title")} onChange={setTitle} defaultValue={title} />
                <TextArea label={t("description")} onChange={setDesc} defaultValue={desc} />
                <TextInput label={t("price")} onChange={setPrice} type="float" defaultValue={price} />
                <TextInput label={t("shipping")} onChange={setShipping} type="float" defaultValue={shipping} />
                <FileInput onChange={setImage} defaultValue={image} />
                <Button loading={loading}>{editId !== 0 ? t("adminGeneral:save-changes") : t("adminGeneral:add")}</Button>
            </form>
        </Page>
    );
};

export default CDs;
