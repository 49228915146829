import { createContext } from "react";
import { ApiFetch } from "../../data/api";
import { GetCDs, GetEvents, GetPage, GetPhotos, GetVideos } from "../../data/api-response";

export interface ApiContextType {
    apiServer: string;
    events: ApiFetch<GetEvents>;
    cds: ApiFetch<GetCDs>;
    videos: ApiFetch<GetVideos>;
    photos: ApiFetch<GetPhotos>;
    pageHome: ApiFetch<GetPage>;
    pageBio: ApiFetch<GetPage>;
    pageDuo: ApiFetch<GetPage>;
    sendMessage: (name: string, email: string, message: string) => Promise<boolean>;
}

export const initialContext: ApiContextType = {
    apiServer: process.env.REACT_APP_API_HOST ?? "",
    events: { isFetching: true },
    cds: { isFetching: true },
    videos: { isFetching: true },
    photos: { isFetching: true },
    pageHome: { isFetching: true },
    pageBio: { isFetching: true },
    pageDuo: { isFetching: true },
    sendMessage: () => new Promise(r => r(false)),
};

const ApiContext = createContext(initialContext);

export default ApiContext;
